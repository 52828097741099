import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

// Sections
import Hero from "../components/home/hero";
import Intro from "../components/home/intro";
import Campaigns from "../components/home/campaigns";
import How from "../components/home/how";
import Made from "../components/home/made";
import Thoughts from "../components/home/thoughts";
import About from "../components/home/about";

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[`sparkrise`, `change`]} title="SparkRise | Home" />

      <div className="relative">
        <Hero className="aspect-ratio-16/9" />
      </div>

      <Intro />
      
      <Campaigns />
      
      <How />

      <Made />

      <Thoughts />

      <About />
    </Layout>
  );
}

export default IndexPage;
