import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import {
  FiFacebook,
  FiTwitter,
  // FiInstagram
} from "react-icons/fi";

const SlashedHeader = styled.header`
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
`;

const SectionAbout = () => {
  const { imageData } = useStaticQuery(
    graphql`
      query {
        imageData: file(relativePath: { eq: "bg-spark.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <section className="bg-purple">
      <SlashedHeader className="relative bg-gradient-to-r from-orange to-purple py-40 flex flex-col">
        <div className="container flex flex-col items-center text-center relative z-10">
          <h3
            className="c-h2 text-white mb-6"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            About Us
          </h3>
          <p
            className="text-white lg:w-8/12"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            The SparkRise team is composed of individuals with backgrounds in
            corporate marketing, philanthropy, media, entertainment, education,
            entrepreneurship, and technology – all of whom have come together
            with the common goal of building a solution that can make this world
            a better place.
          </p>
          <Link
            to="/about"
            className="c-button mt-6"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Meet the Team
          </Link>
        </div>
        <Img
          fluid={imageData.childImageSharp.fluid}
          className="h-full w-full object-cover object-center top-0 left-0 -z-10 blend-luminosity opacity-25"
          style={{
            position: "absolute",
          }}
        />
      </SlashedHeader>

      <div className="container text-center text-white py-12">
        <h4
          className="c-h4 mb-8"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease-out-cubic"
          style={{ "--sal-duration": "1s" }}
        >
          Contact
        </h4>
        <div
          className="flex justify-center items-center flex-wrap space-x-4"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease-out-cubic"
          style={{ "--sal-duration": "1s" }}
        >
          <address className="not-italic c-h6">
            4361 Melrose Avenue, Los Angeles, CA 90029
          </address>
          <div className="hidden lg:block bg-orange h-2 w-2 flex-shrink-0 rounded-full"></div>
          <a
            className="hover:opacity-75 transition-opacity duration-200"
            href="mailto:hello@sparkrise.com"
          >
            hello@sparkrise.com
          </a>
        </div>
        <div className="py-8 flex justify-center items-center flex-wrap space-x-4">
          <a
            href="https://www.facebook.com/SparkRise"
            target="_blank"
            rel="noreferrer"
            aria-label="SparkRise on Facebook"
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
            className="hover:opacity-75 transition-opacity duration-200"
          >
            <FiFacebook />
          </a>
          <a
            href="https://twitter.com/WeAreSparkRise"
            target="_blank"
            rel="noreferrer"
            aria-label="SparkRise on Twitter"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
            className="hover:opacity-75 transition-opacity duration-200"
          >
            <FiTwitter />
          </a>
          {/* <a
            href="#instagram"
            aria-label="SparkRise on Instagram"
            data-sal="slide-left"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <FiInstagram />
          </a> */}
        </div>
        <div>
        <div>Get notified of future events and when event recordings are available</div>
        <html
            dangerouslySetInnerHTML={{
                __html: `
                <!-- Begin Mailchimp Signup Form -->                
                <style type="text/css">
                    #mc_embed_signup{background:#653981; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}
                    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                    /* MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
                    Adapted from: http://blog.heyimcat.com/universal-signup-form/ */

                    #mc_embed_signup form {text-align:center; padding:10px 0 10px 0;}
                    .mc-field-group { display: inline-block; } /* positions input field horizontally */
                    #mc_embed_signup input.email {font-family:"Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif; font-size: 15px; border: 1px solid #ABB0B2;  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; color: #343434; background-color: #fff; box-sizing:border-box; height:32px; padding: 0px 0.4em; display: inline-block; margin: 0; width:350px; vertical-align:top;}
                    #mc_embed_signup label {display:block; font-size:16px; padding-bottom:10px; font-weight:bold;}
                    #mc_embed_signup .clear {display: inline-block;} /* positions button horizontally in line with input */
                    #mc_embed_signup .button {font-size: 13px; border: none; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; letter-spacing: .03em; color: #fff; background-color: #aaa; box-sizing:border-box; height:32px; line-height:32px; padding:0 18px; display: inline-block; margin: 0; transition: all 0.23s ease-in-out 0s;}
                    #mc_embed_signup .button:hover {background-color:#777; cursor:pointer;}
                    #mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%;margin: 0 5%; clear: both;}
                    #mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
                    #mc_embed_signup #mce-error-response {display:none;}
                    #mc_embed_signup #mce-success-response {color:#529214; display:none;}
                    #mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}
                    @media (max-width: 768px) {
                        #mc_embed_signup input.email {width:100%; margin-bottom:5px;}
                        #mc_embed_signup .clear {display: block; width: 100% }
                        #mc_embed_signup .button {width: 100%; margin:0; }
                    }
                </style>
                <div id="mc_embed_signup">
                <form action="https://sparkrise.us5.list-manage.com/subscribe/post?u=c69cb9a17ba4e42a9cc102f2d&amp;id=a98861fd86" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                    <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c69cb9a17ba4e42a9cc102f2d_a98861fd86" tabindex="-1" value=""></div>
                    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                    </div>
                </form>
                </div>
                <!--End mc_embed_signup-->
                `
        }} />
        </div>
      </div>
    </section>
  );
};

export default SectionAbout;
