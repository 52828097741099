import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles note: in order for Gatsby to compile they need to be set to a var and used...
import swiperStyles from 'swiper/swiper.min.css';
import navStyles from 'swiper/components/navigation/navigation.min.css';
import paginateStyles from 'swiper/components/pagination/pagination.min.css';
console.log({ swiperStyles, navStyles, paginateStyles });

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../../../tailwind.config.js");
const fullConfig = resolveConfig(tailwindConfig);

const SectionCampaigns = () => {
  const { topgunPoster } = useStaticQuery(
    graphql`
      query {
        topgunPoster: file(relativePath: { eq: "topgun.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <section className="bg-bg-gray"
      data-sal="fade"
      data-sal-delay="100"
      data-sal-easing="ease-out-cubic"
      style={{ "--sal-duration": "1s" }}>
      <div 
        className="2xl:container p-4 relative"
      >
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
            {/* SLIDE 1 */}
                <SwiperSlide className="lg:aspect-ratio-16/9 bg-white">
                    {/* Desktop Image */}
                    <Img 
                        fluid={topgunPoster.childImageSharp.fluid}
                        className="hidden lg:inline h-auto w-1/3 left-12 top-12 object-fill object-center -z-10"
                        style={{
                        position: "absolute",
                        }}
                    />
                    <div className="h-full w-full top-0 left-0 relative lg:absolute z-10 flex flex-wrap items-center justify-end">
                        <div className="relative lg:hidden h-full w-full mb-10">
                            {/* Mobile Image */}
                            <Img
                              fixed={topgunPoster.childImageSharp.fluid}
                              className="lg:hidden h-full w-full left-0 right-0 top-12 object-fill object-center -z-10 aspect-ratio-3/4 md:aspect-ratio-3/4"
                            />
                        </div>
                        <div className="w-full mr-0 mb-16 lg:w-1/2 lg:mr-28 lg:mb-0 pl-8 pr-8 lg:pr-0 py-4 lg:py-0">
                            <h4 className="c-h4 uppercase text-blue text-center lg:text-center mb-6" style={{ color: fullConfig.theme.colors.orange }}>TOP GUN CELEBRATION</h4>
                            <p className="c-body mb-6" style={{ color: fullConfig.theme.colors.black }}>Join celebrities, Real TOP GUN pilots, US military families and a bunch of Top Gun fans to celebrate.</p>
                            <div className="w-full text-center px-2 lg:px-0 lg:text-center">
                                <a
                                    href="https://www.sparkrise.com/go/topgunliveevent"
                                    className="c-button text-base mt-auto text-center"
                                >TOP GUN Live Streaming Watch Party</a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="absolute top-4 left-0 right-0 z-10 flex flex-col items-center">
                <p className="relative font-semibold c-overline  tracking-widest text-white bg-purple px-4 py-1">Current Campaigns</p>
            </div>
        </div>
    </section>
  );
};

export default SectionCampaigns;