import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

// SVG
import Step1 from "../../images/spark-step1.svg";
import Step2 from "../../images/spark-step2.svg";
import Step3 from "../../images/spark-step3.svg";
import Step4 from "../../images/spark-step4.svg";

import IconCamera from "../../images/icon-camera.svg";
import IconUser from "../../images/icon-user.svg";
import IconGraph from "../../images/icon-graph.svg";
import IconChart from "../../images/icon-chart.svg";

const SlashedHeader = styled.header`
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
`;

const SectionHow = () => {
  const { imageData } = useStaticQuery(
    graphql`
      query {
        imageData: file(relativePath: { eq: "bg-spark.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <section className="bg-light-purple lg:bg-light-gray">
      <SlashedHeader className="bg-gradient-to-r from-orange to-purple relative">
        <h3
          className="container c-h2 text-white text-center py-40 relative z-10 text-shadow"
          data-sal="fade"
          data-sal-delay="100"
          data-sal-easing="ease-out-cubic"
          style={{ "--sal-duration": "1s" }}
        >
          How SparkRise Works
        </h3>
        <Img
          fluid={imageData.childImageSharp.fluid}
          className="h-full w-full object-cover object-center top-0 left-0 -z-10 blend-luminosity opacity-25"
          style={{
            position: "absolute",
          }}
        />
      </SlashedHeader>
      <div className="container mt-6">
        <p
          className="c-h5 lg:w-8/12 mx-auto"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease-out-cubic"
          style={{ "--sal-duration": "1s" }}
        >
          SparkRise brings together brands, influencers, and causes – all who
          want to make a positive impact in our communities. Once a campaign has
          been defined and the players have been assembled, the campaign is
          kicked off by the following steps:
        </p>
        <div className="flex flex-col lg:flex-row-reverse lg:w-10/12 mx-auto gap-4 mt-16 mb-20">
          <div
            className="flex justify-center"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <Step1 />
          </div>
          <div
            className="flex flex-col justify-center"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <h4 className="c-h4 text-purple flex items-center mb-4">
              <IconCamera className="mr-4 text-orange" />
              Step 1
            </h4>
            <p className="c-h6">
              Campaign videos, messages, and media content are created to bring
              awareness to the cause.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:w-10/12 mx-auto gap-4  mb-20">
          <div
            className="flex justify-center"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <Step2 />
          </div>
          <div
            className="flex flex-col justify-center"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <h4 className="c-h4 text-purple flex items-center mb-4">
              <IconUser className="mr-4 text-orange" />
              Step 2
            </h4>
            <p className="c-h6">
              Influencers post videos on their social media, causes send out
              messages via email and social media, and SparkRise works with
              Brands to create great PR for the campaign.
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row-reverse lg:w-10/12 mx-auto gap-4  mb-20">
          <div
            className="flex justify-center"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <Step3 />
          </div>
          <div
            className="flex flex-col justify-center"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <h4 className="c-h4 text-purple flex items-center mb-4">
              <IconGraph className="mr-4 text-orange" />
              Step 3
            </h4>
            <p className="c-h6">
              Fans “spark change” by watching videos, clicking on surveys, 
              downloading a coupon, or just learning about the campaign, cause, 
              and supporters at no cost to them.              
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:w-10/12 mx-auto gap-4  mb-20">
          <div
            className="flex justify-center"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <Step4 />
          </div>
          <div
            className="flex flex-col justify-center"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <h4 className="c-h4 text-purple flex items-center mb-4">
              <IconChart className="mr-4 text-orange" />
              Step 4
            </h4>
            <p className="c-h6">
              SparkRise tracks each engagement, provides reporting, and Brands
              see clear results from their support.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionHow;
