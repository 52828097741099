// const round = (num) =>
//   num
//     .toFixed(7)
//     .replace(/(\.[0-9]+?)0+$/, "$1")
//     .replace(/\.0$/, "");
// const rem = (px) => `${round(px / 16)}rem`
// const em = (px, base) => `${round(px / base)}em`;

// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ["./src/**/*.js"],
  theme: {
    container: {
      center: true,
      padding: "2rem",
    },
    fluidContainer: {
      default: {
        maxWidth: {
          // defaults to null (no max width)
          default: "1280px",
        },
        padding: {
          // defaults to '15px'
          default: "5%",
          xs: "2rem",
        },
      },
    },
    borderWidth: {
      DEFAULT: "1px",
      0: "0",
      2: "2px",
      3: "3px",
      4: "4px",
      6: "6px",
      8: "8px",
      sm: "0.25rem",
      md: "1rem",
      lg: "2rem",
    },
    colors: {
      black: "#000",
      purple: "#653c80",
      rust: "#a95453",
      orange: "#e3672b",
      white: "#fff",
      "bg-gray": "#f4f4f4",
      "light-gray": "#fefcff",
      "light-purple": "#faf8ff",
    },
    fontFamily: {
      sans: ["Montserrat", "sans-serif"],
    },
    fontSize: {
      xs: ["12px", "18px"],
      sm: ["14px"],
      base: ["18px", "30px"],
      lg: ["20px", "30px"],
      xl: ["24px", "34px"],
      "2xl": ["30px", "36px"],
      "3xl": ["36px", "43px"],
      "4xl": ["48px", "56px"],
      "5xl": ["64px", "76px"],
    },
    aspectRatio: {
      // defaults to {}
      none: 0,
      square: [1, 1], // or 1 / 1, or simply 1
      "16/9": [16, 9], // or 16 / 9
      "4/3": [4, 3], // or 4 / 3
      "21/9": [21, 9], // or 21 / 9
      "3/5": [3, 5],
      "3/4": [3, 4],
    },
    filter: {
      // defaults to {}
      none: "none",
      grayscale: "grayscale(1)",
      invert: "invert(1)",
      sepia: "sepia(1)",
    },
    backdropFilter: {
      // defaults to {}
      none: "none",
      blur: "blur(20px)",
    },
    textStyles: (theme) => ({
      // Headline
      heading: {
        output: false,
        lineHeight: theme("lineHeight.tight"),
        fontFamily: theme("fontFamily.sans"),
        fontWeight: theme("fontWeight.semibold"),
      },
      h1: {
        extends: "heading",
        fontSize: theme("fontSize.3xl"),
        fontWeight: theme("fontWeight.bold"),
        "@screen sm": {
          fontSize: theme("fontSize.5xl"),
        },
      },
      h2: {
        extends: "heading",
        fontSize: theme("fontSize.4xl"),
      },
      h3: {
        extends: "heading",
        fontSize: theme("fontSize.3xl"),
      },
      h4: {
        extends: "heading",
        fontSize: theme("fontSize.2xl"),
      },
      h5: {
        extends: "heading",
        fontSize: theme("fontSize.xl"),
      },
      h6: {
        extends: "heading",
        fontSize: theme("fontSize.lg"),
      },
      // Button
      button: {
        display: "inline-block",
        lineHeight: "1",
        fontFamily: theme("fontFamily.sans"),
        letterSpacing: theme("letterSpacing.wider"),
        fontSize: theme("fontSize.sm"),
        fontWeight: theme("fontWeight.semibold"),
        backgroundColor: theme("colors.white"),
        borderWidth: theme("borderWidth.2"),
        borderColor: theme("colors.purple"),
        color: theme("colors.purple"),
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
        paddingTop: "0.625rem",
        paddingBottom: "0.625rem",
        transition: "all 200ms",
        "&:hover": {
          color: theme("colors.white"),
          backgroundColor: theme("colors.purple"),
        },
      },
      // Body
      body: {
        fontSize: theme("fontSize.base"),
        fontWeight: theme("fontWeight.normal"),
        fontFamily: theme("fontFamily.sans"),
      },
      // Body 2
      body2: {
        fontSize: theme("fontSize.sm"),
        fontWeight: theme("fontWeight.normal"),
        fontFamily: theme("fontFamily.sans"),
      },
      // Overline
      overline: {
        fontSize: theme("fontSize.base"),
        fontWeight: theme("fontWeight.bold"),
        fontFamily: theme("fontFamily.narrow"),
        textTransform: "uppercase",
      },
      // Caption
      caption: {
        fontSize: theme("fontSize.xs"),
        fontWeight: theme("fontWeight.normal"),
        fontFamily: theme("fontFamily.sans"),
        letterSpacing: theme("letterSpacing.wide"),
      },
    }),
    extend: {
      typography: (theme) => ({
        DEFAULT: {
          css: {
            li: {
              marginTop: "1rem",
              marginBottom: "1rem",
            },
            "ul > li::before": {
              backgroundColor: theme("colors.black"),
            },
          },
        },
      }),
    },
  },
  // https://github.com/tailwindlabs/tailwindcss-forms
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("tailwindcss-aspect-ratio"),
    require("tailwindcss-typography")(),
    require("tailwindcss-filters"),
    require("tailwindcss-blend-mode")(),
  ],
};
