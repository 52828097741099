import React from "react";
import MediaQuery from "react-responsive";

import NounBadge from "../../images/noun-badge.svg";
import NounCharity from "../../images/noun-charity.svg";
import NounShoppingBag from "../../images/noun-shopping_bag.svg";

const SectionIntro = () => {
  return (
    <>
      <MediaQuery maxWidth={1024}>
        <header className="flex justify-center items-center text-center my-12 px-4">
          <h2
            className="text-orange c-h2"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Creating
            <br />
            100 Million
            <br />
            <small className="text-purple">#sparksofchange</small>
          </h2>
        </header>
      </MediaQuery>
      <section className="py-14 lg:py-24 bg-light-purple lg:bg-light-gray">
        <div className="container">
          <h3
            className="c-h4 lg:w-8/12 mx-auto text-center mb-12"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            <span className="text-purple">SparkRise</span> drives positive
            change throughout communities by connecting brands, causes, and
            influencers with technology that proves making a big impact can be
            simple and rewarding.
          </h3>

          <div className="c-h4 w-8/12 mx-auto text-center flex justify-center">
            <div className="flex flex-col -mx-4">
              <div
                className="bg-orange blend-multiply rounded-full h-36 w-36 flex items-center justify-center"
                data-sal="slide-down"
                data-sal-delay="100"
                data-sal-easing="ease-out-cubic"
                style={{ "--sal-duration": "1s" }}
              >
                <NounShoppingBag className="text-white" />
              </div>
              <span
                className="text-base mt-8"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease-out-cubic"
                style={{ "--sal-duration": "1s" }}
              >
                Brands
              </span>
            </div>

            <div className="flex flex-col -mx-4">
              <div
                className="bg-rust blend-multiply rounded-full h-36 w-36 flex items-center justify-center"
                data-sal="slide-down"
                data-sal-delay="400"
                data-sal-easing="ease-out-cubic"
                style={{ "--sal-duration": "1s" }}
              >
                <NounCharity className="text-white" />
              </div>
              <span
                className="text-base mt-8"
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease-out-cubic"
                style={{ "--sal-duration": "1s" }}
              >
                Causes
              </span>
            </div>

            <div className="flex flex-col -mx-4">
              <div
                className="bg-purple blend-multiply rounded-full h-36 w-36 flex items-center justify-center"
                data-sal="slide-down"
                data-sal-delay="700"
                data-sal-easing="ease-out-cubic"
                style={{ "--sal-duration": "1s" }}
              >
                <NounBadge className="text-white" />
              </div>
              <span
                className="text-base mt-8"
                data-sal="slide-up"
                data-sal-delay="700"
                data-sal-easing="ease-out-cubic"
                style={{ "--sal-duration": "1s" }}
              >
                Influencers
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionIntro;
