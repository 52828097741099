import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import NounQuote from "../../images/noun-quote.svg";

const SectionThoughts = () => {
  const { valKilmer, andraRush, jerryDarnell } = useStaticQuery(graphql`
    query {
      valKilmer: file(relativePath: { eq: "board-val_kilmer.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      andraRush: file(relativePath: { eq: "board-andra_rush.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      jerryDarnell: file(relativePath: { eq: "board-jerry_darnell.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="bg-gradient-to-b from-orange to-purple py-24">
      <div className="container">
        <h4
          className="c-h3 text-black text-center mb-12"
          data-sal="fade"
          data-sal-delay="100"
          data-sal-easing="ease-out-cubic"
          style={{ "--sal-duration": "2s" }}
        >
          Thoughts from Our Partners
        </h4>

        <div className="flex flex-col space-y-12 lg:flex-row lg:space-y-0 lg:space-x-8 lg:justify-center">
          <div className="lg:w-3/12 flex flex-col items-center">
            <div
              className="aspect-ratio-square relative w-full"
              data-sal="slide-down"
              data-sal-delay="100"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              <Img
                fluid={valKilmer.childImageSharp.fluid}
                alt="Val Kilmer Profile Photo"
                className="top-0 left-0 rounded-full border-md border-purple w-full h-full"
                style={{ position: "absolute" }}
              />
            </div>
            <NounQuote
              className="text-orange lg:block my-10 blend-overlay opacity-50"
              data-sal="fade"
              data-sal-delay="100"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            />
            <blockquote
              className="c-h6 text-white font-normal text-center"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              SparkRise takes the pain out of non-profit fundraising. It’s a
              better, faster, easier, smarter way to make a huge difference.
              What’s not to LOVE?
              <cite className="not-italic text-center block mt-8">
                &#8212; Val Kilmer
              </cite>
            </blockquote>
          </div>

          <div className="lg:w-3/12 flex flex-col items-center">
            <div
              className="aspect-ratio-square relative w-full"
              data-sal="slide-down"
              data-sal-delay="100"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              <Img
                fluid={andraRush.childImageSharp.fluid}
                alt="Val Kilmer Profile Photo"
                className="top-0 left-0 rounded-full border-md border-purple w-full h-full"
                style={{ position: "absolute" }}
              />
            </div>
            <NounQuote
              className="text-orange lg:block my-10 blend-overlay opacity-50"
              data-sal="fade"
              data-sal-delay="100"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            />
            <blockquote
              className="c-h6 text-white font-normal text-center"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              I believe SparkRise is the best way for a business to be a leader
              in their community. And with the help of great causes, and
              influencers, we can help heal this planet in ways never before
              possible.
              <cite className="not-italic text-center block mt-8">
                &#8212; Andra Rush
              </cite>
            </blockquote>
          </div>

          <div className="lg:w-3/12 flex flex-col items-center">
            <div
              className="aspect-ratio-square relative w-full"
              data-sal="slide-down"
              data-sal-delay="100"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              <Img
                fluid={jerryDarnell.childImageSharp.fluid}
                alt="Val Kilmer Profile Photo"
                className="top-0 left-0 rounded-full border-md border-purple w-full h-full"
                style={{ position: "absolute" }}
              />
            </div>
            <NounQuote
              className="text-orange lg:block my-10 blend-overlay opacity-50"
              data-sal="fade"
              data-sal-delay="100"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            />
            <blockquote
              className="c-h6 text-white font-normal text-center"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-cubic"
              style={{ "--sal-duration": "1s" }}
            >
              This is a game changer for the franchise industry. Now, franchise
              organizations can get an ROI for their sponsorship dollars that
              produce a return equivalent to traditional advertising campaigns,
              with the added bonus of positive PR and knowing they are helping
              out great causes across the country
              <cite className="not-italic text-center block mt-8">
                &#8212; Jerry Darnell
              </cite>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionThoughts;
