import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";

// Video: desktop
import heroDesktopVp9 from "../../videos/sparkrise_16x9-720p-vp9.webm";
import heroDesktopH265 from "../../videos/sparkrise_16x9-720p-h265.mp4";
import heroDesktopH264 from "../../videos/sparkrise_16x9-720p-h264.mp4";

// Video: tablet
import heroTabletVp9 from "../../videos/sparkrise_3x4-720p-vp9.webm";
import heroTabletH265 from "../../videos/sparkrise_3x4-720p-h265.mp4";
import heroTabletH264 from "../../videos/sparkrise_3x4-720p-h264.mp4";

// Video: mobile
import heroMobileVp9 from "../../videos/sparkrise_3x5-720p-vp9.webm";
import heroMobileH265 from "../../videos/sparkrise_3x5-720p-h265.mp4";
import heroMobileH264 from "../../videos/sparkrise_3x5-720p-h264.mp4";

// Video: captioning (webpack import for vtt not currently working so we base64'd it)
// import HeroVideoCaptions from "../videos/sparkrise-caption.vtt";

const VideoHeroWrap = styled.div`
  height: calc(100vh - 92px);
`;

const BackgroundSection = () => {
  return (
    <VideoHeroWrap className="bg-light-purple relative w-full">
      <video
        className="absolute object-cover top-0 left-0 h-full w-full object-center"
        autoPlay
        muted
        loop
        playsInline
      >
        <track
          src="data:text/vtt;V0VCVlRUIC0gU3BhcmtSaXNlIFByb21vdGlvbiBWaWRlbwoKMDA6MDAuMDAwIC0tPiAwMDowMi4wMDAKVmlkZW8gc2hvd2luZyBwZW9wbGUgdXNpbmcgU3BhcmtSaXNlIG9uIHRoZWlyIG1vYmlsZSBwaG9uZXMgdG8gYnJpbmcgdG9nZXRoZXIgYnJhbmRzLCBpbmZsdWVuY2VycywgYW5kIGNhdXNlcyDigJMgYWxsIHdobyB3YW50IHRvIG1ha2UgYSBwb3NpdGl2ZSBpbXBhY3QgaW4gb3VyIGNvbW11bml0aWVzLg=="
          kind="captions"
          srcLang="en"
          label="english_captions"
        />
        {/* Desktop video */}
        <MediaQuery minWidth={1024}>
          <source src={heroDesktopVp9} type="video/webm; codecs=vp9,opus" />
          <source src={heroDesktopH265} type="video/mp4; codecs=hevc" />
          <source src={heroDesktopH264} type="video/mp4" />
        </MediaQuery>
        {/* Tablet video */}
        <MediaQuery minWidth={768} maxWidth={1024}>
          <source src={heroTabletVp9} type="video/webm; codecs=vp9,opus" />
          <source src={heroTabletH265} type="video/mp4; codecs=hevc" />
          <source src={heroTabletH264} type="video/mp4" />
        </MediaQuery>
        {/* Mobile video */}
        <MediaQuery maxWidth={768}>
          <source src={heroMobileVp9} type="video/webm; codecs=vp9,opus" />
          <source src={heroMobileH265} type="video/mp4; codecs=hevc" />
          <source src={heroMobileH264} type="video/mp4" />
        </MediaQuery>
        Your browser does not support the video tag.
      </video>
      <header className="absolute hidden h-full 2xl:h-screen 2xl:-mt-20 w-full lg:flex lg:justify-start lg:items-end pl-20 pb-16 pointer-events-none">
        <h2 className="text-orange c-h2">
          Creating
          <br />
          100 Million
          <br />
          <small className="text-purple">#sparksofchange</small>
        </h2>
      </header>
    </VideoHeroWrap>
  );
};

BackgroundSection.propTypes = {
  className: PropTypes.string,
};

export default BackgroundSection;
