import React from "react";

import NounBadge from "../../images/noun-badge.svg";
import NounCharity from "../../images/noun-charity.svg";
import NounShoppingBag from "../../images/noun-shopping_bag.svg";

const SectionMade = () => {
  return (
    <section className="container py-24">
      <h4
        className="c-h1 text-orange text-center mb-12"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-easing="ease-out-cubic"
        style={{ "--sal-duration": "1s" }}
      >
        SparkRise is Made For:
      </h4>

      <div className="flex flex-col space-y-12 lg:flex-row lg:space-y-0 lg:space-x-8">
        <div className="lg:w-1/3 flex flex-col items-start">
          <NounCharity
            className="text-orange hidden lg:block mb-6"
            style={{ width: "60px", height: "60px", "--sal-duration": "1s" }}
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
          />
          <h4
            className="c-h4 text-purple mb-3"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Brands
          </h4>
          <p
            className="mb-6 text-base"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Responsible brands want to spark positive change, but the ROI on
            sponsorships is often difficult to measure. SparkRise uses a
            cost-per-engagement model with detailed metrics and trackable
            results which produce an ROI equivalent to traditional advertising.
          </p>
          <a
            href="mailto:hello@sparkrise.com?subject=Hello%20SparkRise&body=I%20want%20to%20spark%20change."
            className="c-button mt-auto"
            style={{ "--sal-duration": "1s" }}
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
          >
            Get Started
          </a>
        </div>
        <div className="lg:w-1/3 flex flex-col items-start">
          <NounShoppingBag
            className="text-orange hidden lg:block mb-6"
            style={{ width: "60px", height: "60px", "--sal-duration": "1s" }}
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
          />
          <h4
            className="c-h4 text-purple mb-3"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Causes
          </h4>
          <p
            className="mb-6 text-base"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Causes don’t pay a dime to participate in a SparkRise campaign, and
            campaigns can be a significant source of revenue and awareness.
            Fostering new relationships with brands and influencers is always a
            great thing too!
          </p>
          <a
            href="mailto:hello@sparkrise.com?subject=Hello%20SparkRise&body=I%20want%20to%20spark%20change."
            className="c-button mt-auto"
          >
            Get Started
          </a>
        </div>
        <div className="lg:w-1/3 flex flex-col items-start">
          <NounBadge
            className="text-orange hidden lg:block mb-6"
            style={{ width: "60px", height: "60px", "--sal-duration": "1s" }}
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
          />
          <h4
            className="c-h4 text-purple mb-3"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Influencers
          </h4>
          <p
            className="mb-6 text-base"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease-out-cubic"
            style={{ "--sal-duration": "1s" }}
          >
            Unlike other partnerships, SparkRise campaigns allow influencers to
            empower their audience to affect massive change with an incredibly
            lightweight time commitment - all administered by a simple turnkey
            engagement. A 30 second video is all it takes to make a huge impact.
          </p>

          <a
            href="mailto:hello@sparkrise.com?subject=Hello%20SparkRise&body=I%20want%20to%20spark%20change."
            className="c-button mt-auto"
          >
            Get Started
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionMade;
